// function initMail( el ){

// 	var send_btn = el.find('a');
// 	send_btn.on( "click", function ( e ) {
//         e.preventDefault();
// 		ojValidate( el );
// 		// var reportValidity = el[0].reportValidity();
// 		// if( reportValidity ) {
// 			// el.submit();
// 		// }
// 	});

// 	el.submit(function(e){
// 		e.preventDefault();
//         alert(0);
// 		// sendMail( el );
// 	});


// 	function sendMail( el ) {

// 		data = {
// 			'action': 'contact_form',
// 			'data'  : el.serialize(),
// 			'post_id' : my_ajax_object.post_id
// 		};

// 		$.ajax({
// 			url: my_ajax_object.ajax_url,
// 			type: 'POST',
// 			data:data,
// 			 success: function(data) {
// 				if( data == '1' ){
// 					TweenLite.to( el, 0, {ease:Power1.easeIn, opacity:0, onComplete: function(){
// 						el.addClass('hidden');
// 						clearForms(el);
// 						var message = el.parent().find('.success-message');
// 						TweenLite.to(message, 0.4, {
// 							ease:Quad.easeOut, 
// 							opacity:"1", 
// 							onComplete: function(){ 
// 							message.removeClass('hidden');
// 						}});
// 					}});
// 				}
	   
// 			},
// 			 error: function(data) {
// 				console.log('error');
// 		   }
// 		});
// 	}

// 	// Clear all forms after submit.
// 	function clearForms(form)
// 	{
// 		$(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
// 		$(':checkbox, :radio').prop('checked', false);

// 		form.each(function(){
// 			this.reset();
// 		});
// 	}


// }


$(document).ready(function(){
    // initMail( $('.form-newsletter') );
});